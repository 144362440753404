/* eslint @typescript-eslint/no-var-requires: "off" */
import { Component, Inject, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import ModalEnum from '@/ship/Enums/ModalEnum';

// Utils
import CanvasDrawer from '@/utils/CanvasDrawer';
import { get, isNumber, sortBy } from 'lodash';

// Interfaces
import { Design, DesignDrawing, DesignRemark, DesignRemarkGroup } from '@/ship/Models/Design';

// API
import oidc from '@/vue-oidc-client';
import { getDesignPageImageUrl, postDesignPageImage } from '@/utils/Api';

interface IndexedDesignRemark extends DesignRemark {
    index: string;
}

@Component
export default class VDesignCanvasMixin extends Vue {
    @VModel({ type: Object, default: () => ({}) })
    public design!: Design;

    @Ref() public contentWrapper!: HTMLDivElement;
    @Ref() public canvasWrapper!: HTMLDivElement;
    @Ref() public canvas!: HTMLCanvasElement;

    @Prop({ type: Object, default: () => ({}) })
    public drawing!: DesignDrawing;

    @Inject() readonly itemType!: string;

    public drawer!: CanvasDrawer;

    // Zoom params
    scaleIndex: number = 0.4;
    readonly scaleUpperBound: number = 2.5;
    readonly scaleLowerBound: number = 0.1;
    readonly padding: number = 0.96;

    // PDF
    pdfPagesCount: number = 0;
    pdfCurrentPage: number = 1;

    isDocumentLoading = false;
    isPageLoading = false;

    get drawings(): DesignDrawing[] {
        return this.design.drawings?.data ?? [];
    }

    get groups(): DesignRemarkGroup[] {
        return this.drawing.groups?.data ?? [];
    }

    set groups(groups: DesignRemarkGroup[]) {
        this.drawing.groups!.data = groups;
    }

    get remarksForDrawing(): IndexedDesignRemark[] {
        const allRemarks = this.groups.reduce((remarks: IndexedDesignRemark[], group, groupIndex) => {
            const layoutRemarks = group.tasks?.data.map((remark, remarkIndex) => ({
                ...remark,
                index: `${groupIndex + 1}.${remarkIndex + 1}`,
            }));
            return remarks.concat(layoutRemarks ?? []);
        }, []);

        const isPin = (remark: DesignRemark) => remark.points.length === 1;

        return sortBy(allRemarks, ['selected', isPin]);
    }

    get isReadyForPreviews() {
        return !this.isDocumentLoading && !!this.pdfPagesCount;
    }

    get isPlan(): boolean {
        return this.itemType === 'plan';
    }

    scaleIndexHeight() {
        return this.contentWrapper
            ? (this.contentWrapper.clientHeight / this.drawer.backgroundImage.height) * this.padding
            : 1;
    }

    scaleIndexWidth() {
        return this.contentWrapper
            ? (this.contentWrapper.clientWidth / this.drawer.backgroundImage.width) * this.padding
            : 1;
    }

    updateScaleIndexWhenImageLoaded() {
        this.scaleIndex = Math.min(this.scaleIndexHeight(), this.scaleIndexWidth());
    }

    adjustDocumentHeight() {
        this.scaleIndex = this.scaleIndexHeight();
        this.drawCanvas();
    }

    adjustDocumentWidth() {
        this.scaleIndex = this.scaleIndexWidth();
        this.drawCanvas();
    }

    @Watch('drawing')
    drawingWatchHandler() {
        if (get(this, '_inactive')) return;
        this.pdfCurrentPage = 1;
        this.initPdfDocument();
    }

    @Watch('remarksForDrawing', { deep: true })
    remarksForDrawingWatchHandler() {
        const selected = this.remarksForDrawing.find((remark) => remark.selected);

        if (selected) {
            const pointStart = selected.points[0];
            const left = pointStart.x * this.scaleIndex - 100;
            const top = pointStart.y * this.scaleIndex - 100;
            this.canvasWrapper.scrollTo({ left, top, behavior: 'smooth' });
        }
    }

    public async mounted() {
        this.drawer = new CanvasDrawer(this.canvas.getContext('2d')!);
        await this.initPdfDocument();
    }

    public drawCanvas() {
        if (!this.canvas) return;
        this.canvas.width = this.drawer.backgroundImage.width * this.scaleIndex;
        this.canvas.height = this.drawer.backgroundImage.height * this.scaleIndex;
        this.canvas.style.width = `${this.canvas.width}px`;
        this.canvas.style.height = `${this.canvas.height}px`;

        this.drawer.drawBackground(this.scaleIndex);
        if (this.isPlan) this.drawGroups();
    }

    drawGroups() {
        this.remarksForDrawing.forEach((remark) => {
            if (remark.pageNumber !== this.pdfCurrentPage) return;
            this.drawer.drawFigure({ ...remark, text: remark.index, scaleIndex: this.scaleIndex });
        });
    }

    async loadPdfPageImage() {
        if (this.isPlan) this.isPageLoading = true;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;
        const image = new Image();
        let url = '';
        if (this.isPlan) {
            url = await getDesignPageImageUrl(this.drawing.uid, this.pdfCurrentPage);
        } else {
            url = require(`@/assets/model-placeholder.jpg`);
        }

        image.src = url;
        image.onload = () => {
            canvas.height = image.height * 2.5;
            canvas.width = image.width * 2.5;
            ctx.drawImage(image, 0, 0);
            this.updateScaleIndexWhenImageLoaded();
            this.drawCanvas();
        };
        if (this.isPlan) this.isPageLoading = false;

        this.drawer.setBackground(image);
        if (this.canvasWrapper) this.canvasWrapper.scrollTop = 0;
    }

    async loadPdfDocument() {
        if (!this.isPlan) return;
        const params = {
            documentSecret: this.drawing.uid,
            url: this.drawing.url,
            responseType: 'blob',
        };
        const headers = { Authorization: 'Bearer ' + oidc.accessToken };

        try {
            this.isDocumentLoading = true;

            const response = await postDesignPageImage(params, headers);
            this.pdfPagesCount = response.data.pagesCount;
        } catch (error) {
            const errorName = `errors.${get(error, 'name')}`;
            const title = this.$t(this.$te(errorName) ? errorName : 'errors.error');
            this.$modal.show(ModalEnum.Alert, { title });
        } finally {
            this.isDocumentLoading = false;
        }
    }

    public setPage(page: number) {
        if (!isNumber(page) || this.pdfCurrentPage === page || this.pdfPagesCount < page || page < 1) return;
        this.pdfCurrentPage = page;
        this.loadPdfPageImage();
    }

    public async initPdfDocument() {
        await this.loadPdfDocument();
        await this.loadPdfPageImage();
    }

    async onDrawingChange(drawingId: number) {
        const drawing = this.drawings.find(({ id }) => id === drawingId);

        if (drawing) {
            this.$emit('setDrawing', drawing);
            await this.$nextTick();
        }
    }
}
